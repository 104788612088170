import api from '@/libs/axios'
import { USER_LEVELS_CONFIG, MODULE_CATEGORY } from './constants'
import configService from './../config/service'

export default {
  async getList(params) {
    let response = await api.get('/admin/users', { params })
    return response
  },
  async get(params) {
    let response = await api.get('/admin/users/detail', {
      params,
    })
    return response.data.data || null
  },
  async getUserRecord(params){
    let response = await api.get('/admin/user-daily-summary', {
      params,
    })
    return response.data.data || null
  },
  async updateUserEmail(data){
    let response = await api.post('/admin/users/update-email', data)
    return response.data.data || null
  },
  async suspend(data){
    let response = await api.post('/admin/users/suspend', data)
    return response.data.data || null
  },
  async getUserLevelsConfig() {
    return await configService.getByKey({ key: USER_LEVELS_CONFIG.key }, { default_data: USER_LEVELS_CONFIG.default_data, category: MODULE_CATEGORY })
  },
  async updateUserLevelsConfig(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
  async getUserLearnProgress(params){
    let response = await api.get('/admin/users/learn-progress', {
      params,
    })
    return response.data.data || null
  },
  async getUserLastAct(params){
    let response = await api.get('/admin/users/user-last-act', {
      params,
    })
    return response.data.data.last_act || null
  },
  async resetEmailCondition(params){
    let response = await api.get('/admin/users/reset-email-condition', {
      params,
    })
    return response.data.data || null
  },
  async updateUserPassword(data){
    let response = await api.post('/admin/users/update-password', data)
    return response.data || null
  },
  async updateTeacherDescription(data){
    let response = await api.post('/admin/users/update-teacher-description', data)
    return response.data || null
  },
  async getIapRecord(params) {
    let response = await api.get('/admin/iap-purchase-record', { params })
    return response.data.data || []
  },
}
